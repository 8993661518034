import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { getIsServer, prepareHeaders } from "../utils";

import type {
	HubspotOwnersDto,
	OrderEntity,
	UpdateOrderDto,
} from "@rototip/lib-order";

const urlPrefix = getIsServer() ? process.env.SVC_ORDER_URL : "";

export const orderApi = createApi({
	tagTypes: ["order"],
	reducerPath: "api:orders",
	baseQuery: fetchBaseQuery({
		baseUrl: `${urlPrefix}/api/order/orders`,
		prepareHeaders,
	}),
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		getOrder: builder.query<OrderEntity, string>({
			query: (orderId) => `/${orderId}`,
			providesTags: ["order"],
		}),
		getUserOrders: builder.query<OrderEntity[], string>({
			query: (userId) => `/user?skipDraft=true`,
			providesTags: ["order"],
		}),
		getAllOrders: builder.query<OrderEntity[], string>({
			query: (userId) => `/`,
			providesTags: ["order"],
		}),
		updateOrder: builder.mutation<OrderEntity, [string, UpdateOrderDto]>({
			query: ([orderId, orderDto]) => ({
				url: `/${orderId}`,
				method: "PATCH",
				body: orderDto,
			}),
		}),
		getOwners: builder.query<HubspotOwnersDto, string>({
			query: (hsDealId) => `/owners/${hsDealId}`,
		}),
		deleteOrder: builder.mutation<OrderEntity, string>({
			query: (orderId) => ({
				url: `/${orderId}`,
				method: "DELETE",
			})
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useGetOrderQuery,
	useGetUserOrdersQuery,
	useGetAllOrdersQuery,
	useUpdateOrderMutation,
	useDeleteOrderMutation,
	useGetOwnersQuery,
} = orderApi;
export const getOrderApiPromises = orderApi.util.getRunningQueriesThunk;

// export endpoints for use in SSR
export const { getOrder, getUserOrders, getAllOrders, updateOrder } =
	orderApi.endpoints;
